// ===== global css =====

html {
    height: 100%;
}

body {
    height: 100%;
    min-height: 100%;
}

.App {
    height: 100%;
}

// to puf some padding to the left of the up/down arrows in a number input field
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { margin-left: 4px; }
